import React from "react"
import { StaticQuery, graphql, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

export default () => (
    <StaticQuery
        query={graphql`
        query {
            background: file(relativePath: { eq: "water.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, maxHeight: 250) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `}
    render = { data => (
        <footer className="layout">
            <BackgroundImage fluid={data.background.childImageSharp.fluid} backgroundColor={`#ffffff`}>
                <div className="layout__wide padding__large">
                    <div className="footer">
                        <div>
                            <Link to="/"><span>NZ Swimming Pools</span></Link>
                            <p>New Zealand made through and through</p>
                        </div>
                        <nav>
                            <ul className="right">
                                <li><Link to="/swimming-pools/" activeClassName="active" partiallyActive={true}>Swimming Pools</Link></li>
                                <li><Link to="/about/" activeClassName="active" partiallyActive={true}>About</Link></li>
                                <li><Link to="/contact/" activeClassName="active" partiallyActive={true}>Contact</Link></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </BackgroundImage>
        </footer>
    )}
    />
)



