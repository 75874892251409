import React from "react"
import { StaticQuery, graphql, Link } from 'gatsby'

class NavMobile extends React.Component 
{
    constructor(props) {
        super(props);
        this.state = {
            showMobile: false,
            navChild: ''
        }
    }

    handleClose = event => {
        event.preventDefault();
		this.setState({
			showMobile: false,
        })
        //console.log(`Close:`, this.state.show);
    }
    
    handleOpen = event => {
        event.preventDefault();
		this.setState({
			showMobile: true,
        })
        //console.log(`Menu:`, this.state.show);
    }

    handleNavChild = event => {
        event.preventDefault();
        let target = event.target.getAttribute('data-id');
        if (this.state.navChild === target) { 
            this.setState({
                navChild: ''
            });
        } else {
            this.setState({
                navChild: target
            })
        }
        //console.log(`Child:`, this.state.navChild);
        //console.log(`Target:`, target);
    }

    render () {
        function getSection (allMarkdownRemarkGroup, section) {
            let result = [];
            for (let edges of allMarkdownRemarkGroup) {
                for (let item of edges.edges) {
                    if (item.node.frontmatter.section === section) {
                        result.push(item.node)
                    }
                }
            }
            result.sort(function(a, b) {
                return a.frontmatter.nav_order - b.frontmatter.nav_order;
            });
            return result;
        }
        
        const NavSection = (p) => <ul className={this.state.navChild === `${p.navChild}` ? `show` : ``}>
            {p.navSectionItems.map( (p, i) => (<li key={i}><Link to={p.fields.slug}>{p.frontmatter.title}</Link></li>) )}
        </ul>

        return (
            <div className="layout">
                <div className={this.state.showMobile ? 'nav__mobile--show' : 'nav__mobile--hide'}>
                    <div className="nav__mobile--overlay"></div>
                    <div className="nav__mobile--close">
                        <button className="nav__mobile--close__link" onClick={this.handleClose}>×</button>
                    </div>
                    <div className="nav__mobile">
                        <div className="nav__mobile--content">
                            <ul>
                                <li className="nav__child"><button onClick={this.handleNavChild} data-id="child_1" title="Swimming Pools">Swimming Pools&nbsp;&nbsp;{this.state.navChild === `child_1` ? `-` : `+`}</button>
                                    <NavSection navChild="child_1" navSectionItems={getSection(this.props.data.group, 'pools')} />
                                </li>
                                <li className="nav__child"><button onClick={this.handleNavChild} data-id="child_2" title="About">About&nbsp;&nbsp;{this.state.navChild === `child_2` ? `-` : `+`}</button>
                                    <NavSection navChild="child_2" navSectionItems={getSection(this.props.data.group, 'about')} />
                                </li>
                                <li><Link to="/contact/">Contact</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="layout nav__mobile__on">
                    <div className="layout__wide">
                        <nav className="nav__top">
                            <button title="Menu" onClick={this.handleOpen} className="nav__menu nav__hamburger--toggle">&#9776; Menu</button>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
}
  
export default () => (
    <StaticQuery
        query={graphql`
            query {
                allMarkdownRemark {
                    group(field: frontmatter___section) {
                        edges {
                            node {
                                fields {
                                    slug
                                }
                                frontmatter {
                                    title
                                    section
                                    nav_order
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => (
            <NavMobile data={data.allMarkdownRemark} />
        )}
    />
)