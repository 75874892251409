import React from "react"
import { StaticQuery, graphql, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

function getSection (allMarkdownRemarkGroup, section) {
    let result = [];
	for (let edges of allMarkdownRemarkGroup) {
        for (let item of edges.edges) {
            if (item.node.frontmatter.section === section) {
                result.push(item.node)
            }
        }
    }
    result.sort(function(a, b) {
        return a.frontmatter.nav_order - b.frontmatter.nav_order;
    });
	return result;
}

const NavSection = (p) => <ul>
    {p.navSectionItems.map( (p, i) => (<li key={i}><Link to={p.fields.slug}>{p.frontmatter.title}</Link></li>) )}
</ul>

export default () => (
    <StaticQuery
        query={graphql`
        query {
            allMarkdownRemark {
                group(field: frontmatter___section) {
                    edges {
                        node {
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                section
                                nav_order
                            }
                        }
                    }
                }
            }
            background: file(relativePath: { eq: "water.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, maxHeight: 250) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `}
    render = { data => (
        <header className="layout">
            <BackgroundImage fluid={data.background.childImageSharp.fluid} backgroundColor={`#ffffff`}>
                <div className="layout__wide logo">
                    <div className="flex flex__space-between">
                        <div className="col__45">
                            <Link to="/"><span>NZ Swimming Pools</span></Link>
                            <p>New Zealand made through and through</p>
                        </div>
                        <nav className="col__45 nav nav__desktop__off">
                            <h2 className="layout__visually-hidden">Site navigation</h2>
                            <ul className="sf__menu right">
                                <li><Link to="/swimming-pools/" activeClassName="active" partiallyActive={true}>Swimming Pools&nbsp;&#9662;</Link>
                                    <div className="nav__mega dark--red">
                                        <div className="nav__child nav__block">
                                            <NavSection navSectionItems={getSection(data.allMarkdownRemark.group, 'pools')} />
                                        </div>
                                    </div>
                                </li>
                                <li><Link to="/about/" activeClassName="active" partiallyActive={true}>About&nbsp;&#9662;</Link>
                                    <div className="nav__mega dark--red">
                                        <div className="nav__child nav__block">
                                            <NavSection navSectionItems={getSection(data.allMarkdownRemark.group, 'about')} />
                                        </div>
                                    </div>
                                </li>
                                <li><Link to="/contact/" activeClassName="active">Contact</Link></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </BackgroundImage>
        </header>
    )}
    />
)


